@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Mobile (max-width: 425px) */
  .mobiled-hidden{
    @media (max-width: 425px) {
      display: none; /* Correct way to hide an element */
    }
  }

  /* Tablet (max-width: 1200px) */
  .tabletd-hidden {
    @media (max-width: 1200px) {
      display: none; /* Correct way to hide an element */
    }
  }

  /* PC (min-width: 1200px) */
  .pcd-hidden {
    @media (min-width: 1200px) {
      display: none; /* Correct way to hide an element */
    }
  }
}

/* Body and general styles */
/* body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #000;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
} */

/* Card container and styles */
/* 
/* Responsiveness */
/* @media (max-width: 1920px) {
  .cards-container {
    transform: scale(0.6);
    transform-origin: top left;
  }
}

@media (max-width: 1600px) {
  .cards-container {
    transform: scale(0.5);
    transform-origin: top left;
  }
}

@media (max-width: 1280px) {
  .cards-container {
    transform: scale(0.4);
    transform-origin: top left;
  }
}

@media (max-width: 1024px) {
  .cards-container {
    transform: scale(0.6);
    transform-origin: top left;
  }
}

@media (max-width: 768px) {
  .cards-container {
    transform: scale(0.5);
    transform-origin: top left;
  }
} */
/* Body and general styles */
/* body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  margin: 0;
  background-color: #000;
  color: white;
  font-family: 'Press Start 2P', sans-serif;
} */

/* Card container and styles */


.card {
  position: absolute;
  width: 700px; /* Width of each card */
  height: 608px; /* Height of each card */
  /* animation-duration: 0.4s; Animation speed */
  /* animation-iteration-count: 1; */
  /* animation-timing-function: ease-in-out; */
  /* animation-fill-mode: forwards; */
  /* left: -250px; Start off-screen */
  /* transition: box-shadow 0.4s ease; */
  /* animation-name: slide; */
}

.card img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

/* Sliding animation */
/* @keyframes slide {
  from {
    left: -250px;
    top: -30px;
  }
  to {
    left: var(--end-l-position);
    top: var(--end-t-position);
  }
} */

/* Individual card positions */
#card1 {
  left: -69px;
  /* animation-delay: 0.4s; */
}
/* #card3:hover {
  --end-l-position:4000px ;
  animation-delay: 0.4s;
  
}  */

#card2 {
  left: 322px;
  top: 185px;
  /* animation-delay: 0.6s; */
}
/* #card4:hover {
  --end-l-position:-3330px ;
  animation-delay: 0.4s;
  
}  */

#card3 {
  left: 340px;
  top: -77px;
  /* animation-delay: 0.8s; */
}
/* #card5:hover {
  --end-l-position:-4000px ;
  animation-delay: 0.4s;
  
}  */


/* Glitch animation styles */
.glitch-container {
  position: relative;
  display: inline-block;
  width: 300px; /* Image width */
  height: 250px; /* Image height */
}

.glitch-container img {
  width: 111%;
  position: absolute;
  height: 125%;
  object-fit: cover;
  animation: glitch1 5s infinite;
}

.glitch-container img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  mix-blend-mode: screen;
  animation: glitch2 5s infinite;
}

.glitch-container img:nth-child(3) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  mix-blend-mode: screen;
  animation: glitch3 5s infinite;
}

/* Glitch animation keyframes */

@keyframes glitch1 {
  0%, 88% {
    transform: none;
    opacity: 1;
  }
  89% {
    transform: translate(-10px, 5px) rotate(2deg) scale(1.05);
    opacity: 0.8;
  }
  90% {
    transform: translate(10px, -5px) rotate(-2deg) scale(0.95);
    opacity: 0.75;
  }
  91%, 92% {
    transform: none;
    opacity: 0.7;
  }
  93% {
    transform: translate(-5px, 10px) rotate(-3deg) scale(1.1);
    opacity: 0.85;
  }
  94% {
    transform: translate(5px, -10px) rotate(3deg) scale(0.9);
    opacity: 0.8;
  }
  95%, 96% {
    transform: none;
    opacity: 0.7;
  }
  97% {
    transform: translate(-15px, 0px) rotate(-1deg) scale(1.02);
    opacity: 0.9;
  }
  98% {
    transform: translate(0px, 15px) rotate(1deg) scale(0.98);
    opacity: 0.75;
  }
  99%, 100% {
    transform: none;
    opacity: 0.7;
  }
}

@keyframes glitch2 {
  0%, 88% {
    transform: none;
    opacity: 1;
  }
  89% {
    transform: translate(10px, -5px) rotate(-2deg) scale(1.05);
    opacity: 0.8;
  }
  90% {
    transform: translate(-10px, 5px) rotate(2deg) scale(0.95);
    opacity: 0.75;
  }
  91%, 92% {
    transform: none;
    opacity: 0.7;
  }
  93% {
    transform: translate(5px, -10px) rotate(3deg) scale(1.1);
    opacity: 0.85;
  }
  94% {
    transform: translate(-5px, 10px) rotate(-3deg) scale(0.9);
    opacity: 0.8;
  }
  95%, 96% {
    transform: none;
    opacity: 0.7;
  }
  97% {
    transform: translate(0px, -15px) rotate(1deg) scale(1.02);
    opacity: 0.9;
  }
  98% {
    transform: translate(-15px, 0px) rotate(-1deg) scale(0.98);
    opacity: 0.75;
  }
  99%, 100% {
    transform: none;
    opacity: 0.7;
  }
}

@keyframes glitch3 {
  0%, 88% {
    transform: none;
    opacity: 1;
  }
  89% {
    transform: translate(-5px, -10px) rotate(3deg) scale(1.05);
    opacity: 0.8;
  }
  90% {
    transform: translate(5px, 10px) rotate(-3deg) scale(0.95);
    opacity: 0.75;
  }
  91%, 92% {
    transform: none;
    opacity: 0.7;
  }
  93% {
    transform: translate(-10px, -5px) rotate(-2deg) scale(1.1);
    opacity: 0.85;
  }
  94% {
    transform: translate(10px, 5px) rotate(2deg) scale(0.9);
    opacity: 0.8;
  }
  95%, 96% {
    transform: none;
    opacity: 0.7;
  }
  97% {
    transform: translate(15px, -15px) rotate(-1deg) scale(1.02);
    opacity: 0.9;
  }
  98% {
    transform: translate(-15px, 15px) rotate(1deg) scale(0.98);
    opacity: 0.75;
  }
  99%, 100% {
    transform: none;
    opacity: 0.7;
  }
}


.glitch-container:hover img {
  width: 111%;
  height: 125%;
  object-fit: cover;
  animation: glitch4 0.5s infinite;
}

.glitch-container:hover img:nth-child(2) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
  mix-blend-mode: screen;
  animation: glitch5 0.5s infinite;
}

.glitch-container:hover img:nth-child(3) {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.75;
  mix-blend-mode: screen;
  animation: glitch6 0.5s infinite;
}

/* Glitch animation keyframes */

@keyframes glitch4 {
  0%, 88% {
    transform: none;
    opacity: 0.7;
  }
  89% {
    transform: translate(30px, -40px) rotate(2deg) scale(1.1);
    opacity: 0.8;
  }
  90% {
    transform: translate(-25px, 35px) rotate(-1deg) scale(0.95);
    opacity: 0.6;
  }
  91%, 92% {
    transform: none;
    opacity: 0.5;
  }
  93% {
    transform: translate(20px, -30px) rotate(-2deg) scale(1.15);
    opacity: 0.85;
  }
  94% {
    transform: translate(-15px, 25px) rotate(1deg) scale(1.05);
    opacity: 0.7;
  }
  95%, 96% {
    transform: none;
    opacity: 0.5;
  }
  97% {
    transform: translate(35px, 10px) rotate(-3deg) scale(1.2);
    opacity: 0.9;
  }
  98%, 100% {
    transform: none;
    opacity: 0.5;
  }
}

@keyframes glitch5 {
  0%, 88% {
    transform: none;
    opacity: 0.7;
  }
  89% {
    transform: translate(-40px, 30px) rotate(-3deg) scale(1.15);
    opacity: 0.8;
  }
  90% {
    transform: translate(20px, -25px) rotate(2deg) scale(1.05);
    opacity: 0.6;
  }
  91%, 92% {
    transform: none;
    opacity: 0.5;
  }
  93% {
    transform: translate(-30px, 20px) rotate(1deg) scale(1.1);
    opacity: 0.85;
  }
  94% {
    transform: translate(15px, -20px) rotate(-2deg) scale(1.05);
    opacity: 0.7;
  }
  95%, 96% {
    transform: none;
    opacity: 0.5;
  }
  97% {
    transform: translate(-10px, 40px) rotate(3deg) scale(1.2);
    opacity: 0.9;
  }
  98%, 100% {
    transform: none;
    opacity: 0.5;
  }
}

@keyframes glitch6 {
  0%, 88% {
    transform: none;
    opacity: 0.7;
  }
  89% {
    transform: translate(30px, 40px) rotate(-3deg) scale(1.1);
    opacity: 0.8;
  }
  90% {
    transform: translate(-20px, -35px) rotate(3deg) scale(0.95);
    opacity: 0.6;
  }
  91%, 92% {
    transform: none;
    opacity: 0.5;
  }
  93% {
    transform: translate(25px, 30px) rotate(1deg) scale(1.2);
    opacity: 0.85;
  }
  94% {
    transform: translate(-15px, -20px) rotate(-2deg) scale(1.05);
    opacity: 0.7;
  }
  95%, 96% {
    transform: none;
    opacity: 0.5;
  }
  97% {
    transform: translate(40px, -10px) rotate(-1deg) scale(1.3);
    opacity: 0.9;
  }
  98%, 100% {
    transform: none;
    opacity: 0.5;
  }
}

/* Responsiveness */

@media (min-width: 1920px) {
  .cards-container {
    position: relative;
    transform: scale(0.7);
    transform-origin: top left;
    left: 110px;
    top: -65px;
  }
}

@media (max-width: 1920px) {
  .cards-container {
    position: relative;
    transform: scale(0.5);
    transform-origin: top left;
    left: 101px;
    top: -65px;
  }
}

@media (max-width: 1600px) {
  .cards-container {
    position: relative;;
    transform: scale(0.5);
    transform-origin: top left;
    left: 41px;
    top: -65px;
  }
}

@media (max-width: 1280px) {
  .cards-container {
    position: relative;
    transform: scale(0.4);
    transform-origin: top left;
    left: 41px;
    top: -65px;
  }
}

@media (max-width: 1024px) {
  .cards-container {
    position: relative;
    transform: scale(0.4);
    transform-origin: top left;
    left: 11px;
    top: -65px;
  }
}

@media (max-width: 768px) {
  .cards-container {
    transform-origin: top left;
    left: -100px;
    height: 880px;
  }
  .glitch {
    display: block;
  }
  .glitch-container {
    width: 100%;
    transform: scale(.7);
  }

}

@media (max-width: 500px) and (min-width: 451px) {
  .cards-container {
    transform-origin: top left;
    left: -17px;
    height: 880px;
  }
}

@media (max-width: 450px) and (min-width: 401px) {
  .cards-container {
    transform-origin: top left;
    left: -10px;
    height: 880px;
  }
}

@media (max-width: 400px) and (min-width: 376px) {
  .cards-container {
    transform-origin: top left;
    left: -33px;
    height: 880px;
  }
}

@media (max-width: 375px) and (min-width: 361px) {
  .cards-container {
    transform-origin: top left;
    left: -38px;
    height: 880px;
  }
}

@media (max-width: 360px) and (min-width: 345px) {
  .cards-container {
    transform-origin: top left;
    left: -45px;
    height: 880px;
  }
}

@media (max-width: 344px) and (min-width: 321px) {
  .cards-container {
    transform-origin: top left;
    left: -54px;
    height: 880px;
  }
}

@media (max-width: 320px) and (min-width: 276px) {
  .cards-container {
    transform-origin: top left;
    left: -64px;
    height: 880px;
  }
}

@media (max-width: 275px) {
  .cards-container {
    transform-origin: top left;
    left: -84px;
    height: 880px;
  }
}
/* 
@media (max-width: 500px) {
  .cards-container {
    transform-origin: top left;
    left: calc(-0.36vw - 1px);
    height: 880px;
  }
} */

.yscroll {
  overflow-y: hidden;
  overflow-x: scroll;
}

.w200vw {
  width: 200px;
}

.resize {
  transform: scale(1.6)
}

.positionrelative {
  position: relative;
}

/* gallery */

@import "https://unpkg.com/open-props@1.7.0/open-props.min.css"
  layer(design.system);
@import "https://unpkg.com/open-props@1.7.0/normalize.dark.min.css"
  layer(design.normalize);
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200..900&family=Lobster&display=swap");

@layer base.override-normalize {
  :root {
    font-family: "Inter Variable", sans-serif;
    --surface-1: oklch(0 0 0);
    --text-1: oklch(0.97 0 0);
    --text-2: oklch(0.73 0 0);
    --link-visited: oklch(0.73 0 0);
  }

  /* a {
    color: var(--text-2);
    display: flex;
    gap: var(--size-1);
  } */
}

.imagecontainer {
  overflow-y: scroll;
}


@layer base.app {
  body {
    --max-inline: 935px;
    --gap: var(--size-fluid-4);
  }

  section {
    display: grid;
    justify-content: center;
    padding-inline: var(--size-1);
    gap: var(--size-7);
  }
}

@layer components.header {
  header {
    display: flex;
    justify-content: center;
    padding-block: var(--gap);
    background-color: var(--surface-1);
    position: sticky;
    inset-block-start: 0;
    z-index: var(--layer-1);

    & h1 {
      font-family: "Lobster", sans-serif;

      font-weight: var(--font-weight-1);
      font-size: clamp(1.5rem, 6vw, 3rem);
    }
  }
}

/* @layer components.gallery { */
  .imageGallery {
    display: grid;
    overflow:auto ;
    height: inherit;
    grid-template-columns: repeat(3, 1fr);
    max-inline-size: var(--max-inline);
    gap: calc(var(--border-size-2) * 2);

    & img {
      aspect-ratio: var(--ratio-square);
      max-inline-size: 100%;
      background-image: var(--gradient-8);
    }

    & > button {
      position: relative;
      padding: 0;
      margin: 0;
      border: none;

      &::after {
        content: "";
        inset: 0;
        display: block;
        background-color: oklch(0 0 0 / 0.4);
        position: absolute;
        background-image: url(https://raw.githubusercontent.com/mobalti/open-props-interfaces/7d7d59bdb5dca71ec7280b88876b45d31cbf3c4a/image-gallery/images/pushpin-fill.svg);
        background-size: var(--size-5);
        background-repeat: no-repeat;
        background-position: top var(--size-1) right var(--size-1);
        opacity: 0;
        transition: 0.2s ease;
      }

      &:hover::after {
        opacity: 1;
      }
    }
  }


@layer components.dialog {
  .dialog {
    --sm-font-size: 0.875rem;
    --size: min(80dvh, 100%);
    --fade-duration: 1s;

    inline-size: var(--size);
    max-inline-size: 600px;
    border-radius: unset;

    padding: 0;

    .dialog-container {
      display: flex;
      flex-direction: column;
      gap: var(--size-3);
      position: relative;
      overflow: hidden;
    }

    .controls {
      position: fixed;
      z-index: var(--layer-1);
      inset-block-start: calc(50% - var(--size-5) / 2);
      inset-inline: var(--size-3);
      display: flex;
      justify-content: space-between;
      opacity: 0;
      animation: var(--animation-fade-in) var(--fade-duration) forwards;

      & button {
        inline-size: var(--size-8);
        border-radius: var(--radius-round);
        aspect-ratio: var(--ratio-square);
        font-size: 1.75rem;
        background: transparent;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        box-shadow: var(--shadow-1);
        color: var(--stone-1);
        transition: color 0.2s ease;
        @media (width > 600px) {
          font-size: 3rem;
        }

        &:hover {
          color: var(--stone-6);
        }
      }
    }

    .closeDialog {
      position: fixed;
      inset-block-start: var(--size-2);
      inset-inline-end: var(--size-2);
      block-size: 48px;
      inline-size: 48px;
      background-color: transparent;
      font-size: var(--font-size-5);
      padding: 0;
    }

    .carousel {
      display: grid;
      grid-auto-flow: column;
      grid-auto-columns: var(--size);
      inline-size: var(--size);
      block-size: var(--size);

      -ms-overflow-style: none;
      scrollbar-width: none;
      &::-webkit-scrollbar {
        display: none;
      }

      & img {
        inline-size: 100%;
        block-size: 100%;
      }

      &::after {
        content: "";
        position: absolute;
        inset: 0;

        background-color: var(--surface-1);

        background-image: url(https://raw.githubusercontent.com/mobalti/open-props-interfaces/7d7d59bdb5dca71ec7280b88876b45d31cbf3c4a/image-gallery/images/bars-rotate-fade.svg);
        background-repeat: no-repeat;
        background-position: center;
        z-index: var(--layer-3);

        animation: var(--animation-fade-out) var(--fade-duration) forwards,
          var(--fade-duration) op-hide 1s forwards;
      }
    }
  }
}

@layer animation.base {
  @keyframes op-hide {
    100% {
      visibility: hidden;
    }
  }
}

@layer animation.scroll-driven {
  body {
    timeline-scope: --carousel;
  }
  #carousel {
    overflow-x: scroll;
    scroll-snap-type: x mandatory;
    overscroll-behavior: contain;
    scroll-behavior: smooth;

    scroll-timeline: --carousel inline;

    > * {
      scroll-snap-align: center;
    }
  }

  .controls-btn {
    animation-duration: auto;
    animation-fill-mode: forwards;
    animation-timeline: --carousel;
    animation-timing-function: linear;

    &.preview {
      animation-name: preview;
    }

    &.next {
      animation-name: next;
    }
  }

  @keyframes preview {
    from {
      visibility: hidden;
    }
  }

  @keyframes next {
    to {
      visibility: hidden;
    }
  }
}

@layer components.footer {
  footer {
    display: flex;
    justify-content: center;
    padding-block: var(--gap);
    font-weight: var(--font-weight-8);
    color: var(--stone-8);
  }
}





* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* body {
  background-color: #f8f8f8;
  font-family: 'Inter', sans-serif;
  color: #5C5E5F;
} */

.container {
  max-width: 1400px;
  margin: 2rem auto;
  padding: 0 1rem;
}

h1 {
  font-size: 2rem;
  margin-bottom: 2rem;
  color: #ffffff;
  text-align: left;
  font-weight: 80;
  opacity: 0;
  transform: translateY(30px);
  animation: fadeUp 0.8s ease forwards;
}

.gallery {
  columns: 3;
  column-gap: 1.5rem;
  width: 100%;
}

@media (max-width: 768px) {
  .gallery {
      columns: 2;
  }
}

@media (max-width: 480px) {
  .gallery {
      columns: 1;
  }
}

.gallery-item {
  /* break-inside: avoid; */
  margin-bottom: 1.5rem;
  position: relative;
  border-radius: 1px;
  /* overflow: hidden; */
  /* max-height: 300px; */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeUp 0.8s ease forwards;
  animation-delay: calc(var(--delay) * 0.2s);
}

/* .gallery-item:hover .overlay {
  opacity: 1;
  transform: translateY(0);
} */

.gallery-item img {
  width: 100%;
  height: auto;
  display: block;
  transition: transform 0.3s ease;
}

.gallery-item:hover img {
  transform: scale(1.05);
}

.overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(to top, rgba(0, 168, 157, 0.9), transparent);
  padding: 2rem 1.5rem;
  color: white;
  opacity: 0;
  transform: translateY(20px);
  transition: all 0.3s ease;
}

.overlay h2 {
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
  font-weight: 600;
}

.overlay p {
  font-size: 0.9rem;
  line-height: 1.4;
  opacity: 0.9;
}

.photo-credit {
  position: absolute;
  bottom: 10px;
  right: 10px;
  color: white;
  font-size: 0.8rem;
  opacity: 0.8;
  text-decoration: none;
}

.photo-credit:hover {
  opacity: 1;
}

@keyframes fadeUp {
  to {
      opacity: 1;
      transform: translateY(0);
  }
}

.scroll-indicator {
  position: fixed;
  top: 0;
  left: 0;
  height: 3px;
  background: #00A89D;
  z-index: 1000;
  transition: width 0.3s ease;
}



/* registration form */
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

/* body {
    margin: 0;
    padding: 0;
    font-family: 'Noto Sans', sans-serif;
} */

    /* body * { box-sizing: border-box; } */

    .main-login {
      overflow-x: hidden;
      width: 100%;
      height: auto;
      background: #000000;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  
  .formbox {
    width: 100%;
  }
  
  .left-login {
      width: 50vw;
      height: 100vh;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding-top: 100px;
      padding-bottom: 100px;
      
  }
  
      .left-login > h1 { color: #ffffff; }
  
  .left-login-img {
      width: 35vw;
  }
  
  .right-login {
      width: 50vw;
      height: auto;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-top: 100px;
      padding-bottom: 100px;

  }
  
  .card-login {
      width: 80%;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      padding: 30px 35px;
      background: #000000;
      border-width: 2px;
      border-radius: 20px;
      box-shadow: 0px 10px 40px #00000056;
  }
  
      .card-login > h1 {
          color: #ffffff;
          font-weight: 800;
          margin: 0;
      }
  
  .textfield {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      margin: 10px 0;
  }
  
      .textfield > input {
          width: 100%;
          border: none;
          border-radius: 10px;
          padding: 15px;
          background: #2c2c2c;
          color: #f0ffffde;
          font-size: 12px;
          box-shadow: 0px 10px 40px #00000056;
          outline: none;
          box-sizing: border-box;
      }
  
          .textfield > input::placeholder { color: #f0ffff94; }
  
      .textfield > label {
          color: #f0ffffde;
          margin-bottom: 10px;
      }
  
  .textfield > select {
      width: 100%;
      border: none;
      border-radius: 10px;
      padding: 15px;
      background: #2c2c2c;
      color: #f0ffffde;
      font-size: 12px;
      box-shadow: 0px 10px 40px #00000056;
      outline: none;
      box-sizing: border-box;
  }
  
  .textfield > select:focus {
      border-color: #c02121;
      box-shadow: 0px 10px 40px #c02121;
  }
  
  .textfield > select option {
      background: #2c2c2c;
      color: #f0ffffde;
      font-size: 12px;
      padding: 10px;
  }
  
  .btn-login {
      width: 100%;
      padding: 16px 0;
      margin-top: 25px;
      border: none;
      text-transform: uppercase;
      border-radius: 8px;
      outline: none;
      font-weight: 800;
      letter-spacing: 3px;
      color: #ffffff;
      background: #c02121;
      cursor: pointer;
      box-shadow: 0px 10px 40px -12px #360a0a;
  }
  .btn-login:hover{
    opacity: 50%;
    
  }
  
  @media only screen and (max-width: 950px) {
      .card-login { width: 85%; }
  }
  
  @media only screen and (max-width: 600px) {
      .main-login { flex-direction: column; }
      .left-login { width: 100%; height: auto; }
          .left-login > h1 { display: none; }
  
      .right-login { width: 100%; height: auto; }
      .left-login-img { width: 50vw; }
      .card-login { width: 90%; }
  }
  
/* drop down */

/* custom scrollwheel */
@layer components {
  /* General Styles for Firefox */
  .scrollbar-custom {
    scrollbar-width:normal; /* Makes scrollbar thin */
    scrollbar-color: #ff0000 #101010; /* Thumb color and track color */
  }

  /* Webkit Browsers (Chrome, Safari, Edge) */
  .scrollbar-custom::-webkit-scrollbar {
    width: 15px; /* Vertical scrollbar width */
    height: 15px; /* Horizontal scrollbar height */
  }

  .scrollbar-custom::-webkit-scrollbar-track {
    background-color: #101010; /* Track background color */
    border-radius: 10px; /* Rounded corners for track */
    border: 0.5px solid white; /* Optional border color */
  }

  .scrollbar-custom::-webkit-scrollbar-thumb {
    background-color: #ff0000; /* Thumb color */
    border-radius: 12px; /* Rounded corners for thumb */
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); /* Inner shadow effect */
  }

  .scrollbar-custom::-webkit-scrollbar-thumb:hover {
    background-color: #ff4444; /* Thumb hover color */
  }

  .scrollbar-custom::-webkit-scrollbar-button {
    display: none; /* Hides arrows on the scrollbar */
  }

  /* IE 10+ (Fallback for older browsers) */
  .scrollbar-custom {
    -ms-overflow-style: scrollbar; /* Ensures scrollbars appear in IE */
  }
}

.crossword-wrapper rect {
  rx: 1; /* Adjust this value to change the roundness */
  ry: 1;
}

@media (min-width: 1080px) {
.custom-width {
  justify-content: center;
  max-width: 100%;
  padding-bottom: 300px;
  padding-top: 300px;
  transform: scale(8);
  width: 600px !important;
} 
}

@media (max-width: 1080px) {
  .custom-width {
    justify-content: center;
    max-width: 100%;
    transform: scale(5);
    width: 600px !important;
    padding-top: 180px;
    padding-bottom: 180px;
    padding-left: 15px;
} 
}

input[aria-label="crossword-input"] {
  width: 0 !important;
  height: 0 !important;
  padding: 0 !important;
  margin: 0 !important;
  border: 0 !important;
  /* display: none; Optional: Hides the input completely if needed */
}

/* body {
  width: 100%;
  height: fit-content;
  margin: 0;
  padding: 0;
  font-family: "Poppins", sans-serif;
} */
/* 
.stack-area {
  width: 100%;
  height: 300vh;
  position: relative;
  background: rgba(0, 0, 0, 0);
  display: flex;
}

.left {
  height: 100vh;
  flex-basis: 50%;
  position: sticky;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
}

.title {
  width: 420px;
  font-size: 84px;
  font-weight: 700;
  line-height: 88px;
}

.sub-title {
  width: 420px;
  font-size: 14px;
  margin-top: 30px;
}

.sub-title button {
  font-size: 14px;
  padding: 15px 30px;
  background: black;
  color: white;
  border-radius: 8mm;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 20px;
}

.card {
  width: 350px;
  height: 350px;
  border-radius: 25px;
  margin-bottom: 10px;
  position: absolute;
  top: calc(50% - 175px);
  left: calc(50% - 175px);
  transition: 0.5s ease-in-out;
  padding: 35px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.card:nth-child(1) {
  background: rgb(64, 122, 255);
}

.card:nth-child(2) {
  background: rgb(221, 62, 88);
}

.card:nth-child(3) {
  background: rgb(186, 113, 245);
}

.card:nth-child(4) {
  background: rgb(247, 92, 208);
}

.sub {
  font-size: 20px;
  font-weight: 700;
}

.content {
  font-size: 44px;
  font-weight: 700;
  line-height: 54px;
}

.away {
  transform-origin: bottom left;
} */


.sidebar {
  transition: opacity 0.3s ease, transform 0.3s ease;
}