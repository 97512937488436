@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Mobile (max-width: 425px) */
  .mobiled\\:hidden {
    @media (max-width: 425px) {
      display: none; /* Correct way to hide an element */
    }
  }

  /* Tablet (max-width: 1200px) */
  .tabletd\\:hidden {
    @media (max-width: 1200px) {
      display: none; /* Correct way to hide an element */
    }
  }

  /* PC (min-width: 1200px) */
  .pcd\\:hidden {
    @media (min-width: 1200px) {
      display: none; /* Correct way to hide an element */
    }
  }
}



body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:where(p) {
  max-inline-size: initial !important;
  text-wrap: pretty !important;
}

:where(h2) {
  font-size: var(--font-size-6);
  max-inline-size: initial !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

spinner {
  position : absolute
  
}

.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-ellipsis div {
  position: absolute;
  top: 33px;
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 8px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 8px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 32px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 56px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

@media (max-width: 1060px) {
  .navbargrid {
  display: none;  }
}